@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
    color-scheme: dark;
}

.hash-span {
    margin-top: -100px;
    padding-bottom: 100px;
    display: block;
}

.black-gradient {
    background: #000000;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #434343,
            #000000);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #434343,
            #000000);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.blue-gradient {
    background: #0f0a48;
    background: -webkit-linear-gradient(to right,
            #0f0a48,
            #1e194b);
    background: linear-gradient(to right,
            #0f0a48,
            #1e194b);
}

.violet-gradient {
    background: #804dee;
    background: linear-gradient(-90deg, #804dee 0%, rgba(60, 51, 80, 0) 100%);
    background: -webkit-linear-gradient(-90deg,
            #804dee 0%,
            rgba(60, 51, 80, 0) 100%);
}

.green-pink-gradient {
    background: "#00cea8";
    background: linear-gradient(90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
    background: -webkit-linear-gradient(-90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
}

.orange-text-gradient {
    background: #f12711;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to top,
            #f12711,
            #f5af19);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top,
            #f12711,
            #f5af19);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.green-text-gradient {
    background: #11998e;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to top,
            #11998e,
            #38ef7d);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top,
            #11998e,
            #38ef7d);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.blue-text-gradient {
    /* background: -webkit-linear-gradient(#eee, #333); */
    background: #56ccf2;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to top,
            #2f80ed,
            #56ccf2);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top,
            #2f80ed,
            #56ccf2);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pink-text-gradient {
    background: #ec008c;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to top,
            #ec008c,
            #fc6767);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top,
            #ec008c,
            #fc6767);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* canvas- styles */
.canvas-loader {
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.1s infinite ease;
    transform: translateZ(0);
}

@keyframes mulShdSpin {

    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #ffffff,
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
            1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
    }
}