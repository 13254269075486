.component-container {
    padding: 4vw;
    justify-content: center;
}

.slider {
    position: relative;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 500px;
    height: auto;
    border-radius: 10px;
    transition: transform 0.2s;
}

.image:hover {
    transform: scale(1.1);
    transform: rotate(-0.1turn);
}

.right-arrow {
    position: absolute;
    top: 50%;
    right: 5px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.left-arrow {
    position: absolute;
    top: 50%;
    left: 5px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}

.img-container {
    width: 40vw;
    justify-content: center;
}

.img-box {
    border-radius: 50px;
    width: 18%;
    margin: 10px;
    height: 50vh;
    object-fit: cover;
    transition: width 0.8s;
}

.img-box:hover {
    width: 70%;
    cursor: pointer;
}

@media only screen and (max-width: 765px) {
    .img-container {
        width: 100vw;
        justify-content: center;
    }

    .img-box {
        border-radius: 50px;
        width: 18%;
        margin: 10px;
        height: 50vh;
        object-fit: cover;
        transition: width 0.8s;
    }

    .img-box:hover {
        width: 40%;
        cursor: pointer;
    }

}